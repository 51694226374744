import React, { useState, useEffect } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';

const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

const DrillDownChart = ({ eventData, allMetrics, defaultSelectedMetrics }) => {
  const [selectedMetrics, setSelectedMetrics] = useState(
    defaultSelectedMetrics || []
  );
  const [chartData, setChartData] = useState({ series: [] });
  const [xAxisGrouping, setXAxisGrouping] = useState('days_out');
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    updateChartData(selectedMetrics, xAxisGrouping);
  }, [eventData, selectedMetrics, xAxisGrouping]);

  const metricOptions = allMetrics.map((metricName) => ({
    name: metricName,
  }));

  const handleChange = (event) => {
    const selectedNames = event.target.value;
    setSelectedMetrics(selectedNames);
  };

  const updateChartData = (selectedNames, xGrouping) => {
    // Ensure selectedNames contains unique metric names
    const uniqueSelectedNames = [...new Set(selectedNames)];
  
    // Build a set of all categories from event data
    const categoriesSet = new Set();
    eventData.forEach((event) => {
      const category =
        xGrouping === 'days_out'
          ? Number(event.days_out)
          : dayjs(event.sale_date).valueOf();
      categoriesSet.add(category);
    });
  
    // Convert set to sorted array
    const categories = Array.from(categoriesSet).sort((a, b) => a - b);
  
    const isDaysOut = xGrouping === 'days_out';
  
    // Calculate the maximum "Days Out" value
    let maxDaysOut = 0;
    if (isDaysOut) {
      maxDaysOut = Math.max(...eventData.map((event) => Number(event.days_out)));
    }
  
    // Build a data map for quick lookup
    const dataMap = {};
    eventData.forEach((event) => {
      const eventId = event.event_id;
      const category =
        xGrouping === 'days_out'
          ? Number(event.days_out)
          : dayjs(event.sale_date).valueOf();
      if (!dataMap[eventId]) dataMap[eventId] = {};
      dataMap[eventId][category] = event;
    });
  
    // Map metrics to y-axis indices
    const metricYAxisMap = {};
    let yAxisIndex = 0;
    uniqueSelectedNames.forEach((metricName) => {
      if (!(metricName in metricYAxisMap)) {
        metricYAxisMap[metricName] = yAxisIndex++;
      }
    });
  
    const newSeries = [];
    uniqueSelectedNames.forEach((metricName) => {
      const yAxisIdx = metricYAxisMap[metricName];
      Object.keys(dataMap).forEach((eventId) => {
        const seriesName = `${capitalize(metricName)} - ${eventId}`;
        const data = categories.map((category) => {
          const event = dataMap[eventId][category];
          let xValue = category;
  
          // Transform xValue if "Days Out" is selected
          if (isDaysOut) {
            xValue = maxDaysOut - category;
          }
  
          const yValue =
            event && event[metricName] !== undefined
              ? event[metricName]
              : null;
          return { x: xValue, y: yValue };
        });
        newSeries.push({ name: seriesName, data, yAxisIndex: yAxisIdx });
      });
    });
  
    console.log('newSeries:', newSeries);
  
    setChartData({ series: newSeries });
    updateChartOptions(newSeries, maxDaysOut, metricYAxisMap);
  };

  const updateChartOptions = (newSeries, maxDaysOut, metricYAxisMap) => {
    const isDaysOut = xAxisGrouping === 'days_out';
  
    // Collect all used yAxisIndices
    const yAxisIndices = new Set(newSeries.map((series) => series.yAxisIndex));
    const yAxisIndicesArray = Array.from(yAxisIndices).sort((a, b) => a - b);
  
    // Initialize yAxisConfigs
    const yAxisConfigs = [];
  
    yAxisIndicesArray.forEach((yAxisIdx) => {
      // Find the metric name corresponding to this yAxisIdx
      const metricName = Object.keys(metricYAxisMap).find(
        (key) => metricYAxisMap[key] === yAxisIdx
      );
  
      yAxisConfigs[yAxisIdx] = {
        title: { text: metricName ? capitalize(metricName) : `Y Axis ${yAxisIdx + 1}` },
        opposite: yAxisIdx % 2 === 1,
        labels: {
          formatter: (value) => {
            if (value === null || value === undefined) {
              return '';
            }
            if (Math.abs(value) >= 1) {
              return Math.round(value).toLocaleString();
            } else {
              return value.toFixed(2);
            }
          },
        },
      };
    });
  
    // Calculate xaxisMin and xaxisMax based on transformed x-values
    const allXValues = newSeries
      .flatMap((series) => series.data.map((point) => point.x))
      .filter((x) => x !== null && x !== undefined);
  
    const xaxisMax = Math.max(...allXValues);
    const xaxisMin = Math.min(...allXValues);
  
    // Set xaxisMin to display only the last 45 days
    const initialXaxisMin = isDaysOut
      ? Math.max(xaxisMin, xaxisMax - 45) // For "Days Out"
      : xaxisMax - 45 * 24 * 60 * 60 * 1000; // For "Sale Date"
  
    const newChartOptions = {
      chart: {
        id: 'mainChart',
        type: 'line',
        toolbar: { show: true },
        zoom: {
          enabled: true,
          type: 'xy',
          autoScaleYaxis: true,
          allowMouseWheelZoom: false,
        },
        animations: {
          enabled: true,
        },
      },
      markers: { size: 3, strokeWidth: 1 },
      stroke: { width: 3, curve: 'smooth', lineCap: 'round' },
      xaxis: {
        type: isDaysOut ? 'numeric' : 'datetime',
        min: initialXaxisMin,
        max: xaxisMax,
        tickAmount: 10,
        labels: {
          rotate: -45,
          formatter: (value) => {
            if (value === undefined || value === null) {
              return '';
            }
            if (isDaysOut) {
              const originalDaysOut =
                maxDaysOut !== undefined ? maxDaysOut - value : value;
              return Number(originalDaysOut).toFixed(0);
            } else {
              return dayjs(value).format('MM/DD');
            }
          },
        },
        title: { text: isDaysOut ? 'Days Out' : 'Sale Date' },
      },
      yaxis: yAxisConfigs,
      legend: { position: 'bottom', horizontalAlign: 'left' },
    };
  
    setChartOptions(newChartOptions);
  };

  const handleXAxisChange = (event) => {
    const selectedValue = event.target.value;
    setXAxisGrouping(selectedValue);
  };

  return (
    <div>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <FormControl sx={{ mt: 3, width: 300 }}>
            <InputLabel id="metric-select-label">Metrics</InputLabel>
            <Select
              labelId="metric-select-label"
              multiple
              value={selectedMetrics}
              onChange={handleChange}
              input={<OutlinedInput label="Metrics" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {metricOptions.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  <Checkbox
                    checked={selectedMetrics.includes(option.name)}
                  />
                  <ListItemText
                    primary={option.name
                      .split('_')
                      .map(capitalize)
                      .join(' ')}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ mt: 3, width: 200 }}>
            <InputLabel id="x-axis-grouping-label">
              X-Axis Grouping
            </InputLabel>
            <Select
              labelId="x-axis-grouping-label"
              value={xAxisGrouping}
              onChange={handleXAxisChange}
              input={<OutlinedInput label="X-Axis Grouping" />}
            >
              <MenuItem value="days_out">Days Out</MenuItem>
              <MenuItem value="sale_date">Sale Date</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Chart
          options={chartOptions}
          series={chartData.series}
          type="line"
          height={450}
          style={{ marginTop: 10 }}
        />
      </Box>
    </div>
  );
};

export default DrillDownChart;